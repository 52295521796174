/**
 * Custom object included in HttpException responses from Bottlenose.
 * TODO(ryder): Split this file up into more smaller 'entities-*' files.
 */

export type Side = 'left' | 'right';
// NOTE: This must be kept in sync with api/bottlenose/src/whalesync-http-exception.ts.
export type HttpErrorInfo = {
  userFacingMessage: string;
  debugMessage?: string;
  validationProblem?: DataValidationProblem;
};

export type HttpException = {
  statusCode: number;
  /** Formatted as an ISO timestamp. */
  timestamp: string;
  path: string;
  message: string;
  response: HttpErrorInfo;
};

export enum CoreBaseSyncState {
  OFF = 'off',
  ON = 'on',
}

/**
 * Enum of pricing slugs that are visible in URLs when users pick a subscription plan. On the backend we link these to
 * Stripe plans.
 *
 * Note: For newer pricing (after version 5.0), we use random IDs to identify them. These are slugs visible in URLs so
 * making them unique but opaque is what we want so that users can't derive meaning from them.
 *
 * For short IDs, here's a good spot to generate them: https://shortunique.id/.
 */
export enum ProductType {
  STARTER_1_V80 = 'NLuhHm', // 10,000 records, 5 syncs.
  OPERATOR_1_V80 = 'Z1bZQe', // 20,000 records, 10 syncs.
  PROFESSIONAL_1_V80 = 'Vu3vlb', // 50,000 records, 100 syncs.
  PROFESSIONAL_2_V80 = 'vTlL5q', // 100,000 records, 100 syncs.
  PROFESSIONAL_3_V80 = 'eeNJKV', // 500,000 records, 100 syncs.
  PROFESSIONAL_4_V80 = 'XcnOyc', // 1,000,000 records, 100 syncs.

  STARTER_1_V70 = '01zEEq', // 10,000 record, 5 bases.
  PROFESSIONAL_1_V70 = 'ZxBo4K', // "Call us"

  PROJECT_1_V60 = 'j48Tyk', // 1,000 records, 1 base.
  PROJECT_2_V60 = 'eUIXGg', // 2,000 records, 1 base.
  PROJECT_3_V60 = 'TUefSS', // 5,000 records, 1 base.
  PROJECT_4_V60 = 'KStMqH', // 10,000 records, 1 base.
  STARTUP_1_V60 = 'Am6nCr', // 2,000 records, 5 bases.
  STARTUP_2_V60 = 'uKuqEK', // 5,000 records, 5 bases.
  STARTUP_3_V60 = 'lkn14P', // 10,000 records, 5 bases.
  STARTUP_4_V60 = '9KVi0d', // 15,000 records, 5 bases.
  STARTUP_5_V60 = 'l7vse8', // 20,000 records, 5 bases.
  STARTUP_6_V60 = 'DsZlrc', // 25,000 records, 5 bases.
  STARTUP_7_V60 = 'LcWpR5', // 30,000 records, 5 bases.
  BUSINESS_1_V60 = 'nEUhxl', // 50,000 records, 100 bases.
  BUSINESS_2_V60 = 'LVlGjt', // 100,000 records, 100 bases.
  BUSINESS_3_V60 = 'IDAirM', // 500,000 records, 100 bases.
  BUSINESS_4_V60 = 'DoqP3p', // 1,000,000 records, 100 bases.

  PROJECT_V50 = 'guPnfS', // 2,000 records, 1 base.
  STARTUP_V50 = 'qdpvLI', // 10,000 records, 5 bases.
  BUSINESS_1_V50 = 'jZXhPI', // 50,000 records, 100 bases.
  BUSINESS_2_V50 = '36FEUF', // 100,000 records, 100 bases.
  BUSINESS_3_V50 = 'W9w4ij', // 500,000 records, 100 bases.
  BUSINESS_4_V50 = 'Takymx', // 1,000,000 records, 100 bases.

  // Older.
  STARTUP_V40 = 'startup', // 1,000 records, 2 Whalesync bases. Now called "Project" in the UI. $39/mo.
  STARTUP_3_V40 = 'startup_3', // 2,000 records, 2 Whalesync bases. Now called "Project" in the UI. $69/mo.
  STARTUP_PLUS_V40 = 'startup_plus', // 1,000 records, 5 Whalesync bases. Called "Startup" in the UI. $79/mo.
  STARTUP_PLUS_2_V40 = 'startup_plus_2', // 2,000 records, 5 Whalesync bases. Called "Startup" in the UI. $99/mo.
  STARTUP_PLUS_3_V40 = 'startup_plus_3', // 5,000 records, 5 Whalesync bases. Called "Startup" in the UI. $149/mo.
  STARTUP_PLUS_4_V40 = 'startup_plus_4', // 10,000 records, 5 Whalesync bases. Called "Startup" in the UI. $179/mo.
  BUSINESS_V40 = 'business', // 10,000 records, 10 Whalesync bases. $329/mo.
  BUSINESS_2_V40 = 'business_2', // 20,000 records, 10 Whalesync bases. $479/mo.
  BUSINESS_3_V40 = 'business_3', // 50,000 records, 10 Whalesync bases. $649/mo.

  // Historical, not available for new signups, but may exist in the database for current users.
  STARTUP_2_VOLD = 'startup_2', // 5,000 records. Repurposing the name "startup" to refer to a new product id.
  STARTER_VOLD = 'starter', // 2,000 records.
  PRO_VOLD = 'pro', // 10,000 records.
  GROWTH_VOLD = 'growth', // 100,000 records.
}

export type Success = 'success';

export type ColumnTypeMetadata = {
  supportsRead: boolean;
  supportsWrite: boolean;
  writeOnce?: boolean;
  required: boolean;
  requiresDeepPolling?: boolean;
  displayName?: string;
  displayIcon?: ConnectorColumnIcon;
  allowsMultipleValues: boolean;
  mappingDisabled?: true;

  // Type-specific details:
  numericalMetadata?: NumericalColumnTypeMetadata;
  stringMetadata?: StringColumnTypeMetadata;
  richTextMetadata?: RichTextColumnTypeMetadata;
  booleanMetadata?: BooleanColumnTypeMetadata;
  urlMetadata?: UrlColumnTypeMetadata;
  dateMetadata?: DateColumnTypeMetadata;
  currencyMetadata?: CurrencyColumnTypeMetadata;
  jsonObjectMetadata?: JsonObjectColumnTypeMetadata;
  foreignKeyMetadata?: ForeignKeyColumnTypeMetadata;
};

export type NumericalColumnTypeMetadata = {
  isNumerical: true;
  type?: 'decimal' | 'integer';
  decimalPrecision?: number;
  minAllowedValue?: number;
  maxAllowedValue?: number;
};

export type StringColumnTypeMetadata = {
  isMultilineString?: boolean;
  restrictedToOptions?: string[];
  isEmailAddress?: boolean;
  isPhoneNumber?: boolean;
  isUUID?: boolean;
  isColor?: boolean;
};

export type RichTextColumnTypeMetadata = {
  isHtml: true;
};

export type BooleanColumnTypeMetadata = {
  isBoolean: true;
};

export type UrlColumnTypeMetadata = {
  isUrl: true;
  isImageUrl?: boolean;
  urlMimeType?: string;
};

export type DateColumnTypeMetadata = {
  isDate: true;
  format: 'dateonly' | 'datetime';
};

export type CurrencyColumnTypeMetadata = {
  isCurrency: true;
  type?: 'decimal' | 'smallest_unit';
  defaultCurrencyCode: string;
  keys?: {
    currencyKey: string;
    valueKey: string;
  };
};

export type JsonObjectColumnTypeMetadata = {
  isJsonObject: true;
  jsonSchema?: JsonSafeValue;
};

export type ForeignKeyColumnTypeMetadata = {
  isForeignKey: true;
  foreignKeyRemoteTableId: string;
};

// ================================================================================
// Types
// ================================================================================

export type UUID = `${string}-${string}-${string}-${string}-${string}`;
export const NIL_UUID = '00000000-0000-0000-0000-000000000000';
export type JsonSafeValue = string | number | boolean | null | { [Key in string]?: JsonSafeValue } | JsonSafeValue[];

export interface AuthApiRequestArgs {
  token: string;

  /**
   * The user to impersonate when making the query.
   * Requires Admin permissions.
   * This can also be set globally via the dev toolbox. When provided here per-request, it overrides that value.
   */
  userToImpersonateId?: string;
}

export type ExternalPollingJobSavedState = {
  offset?: string;
  offsetNum?: number;
  totalNum?: number;
};

export interface User {
  id?: UUID;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  version?: number;
  supabaseId?: string;
  clerkId?: string;
  firstName?: string;
  lastName?: string;
  mostRecentEmail?: string;
  coreBases?: CoreBase[];
  externalConnections?: ExternalConnection[];
  /**
   * The user's most recent plan.
   * If the plan has expired, it will be listed here but with status='expired'.
   * If there is no plan, this will be undefined.
   */
  subscription?: {
    status: 'valid' | 'expired';
    planDisplayName: string;
    daysRemaining: number;
  };
  experimentFlags: UserExperimentFlags;
  intercomHash: string;
  /** JWT token for using the iApp APIs */
  iAppToken?: string;
}

export interface UserExperimentFlags {
  /** Enable the user to edit a sync setting for a given table mapping direction that controls whether deletes are synced. */
  SYNC_SETTING_NOT_DELETES?: boolean;
  /** Has access to the dev toolbox on dusky. */
  DUSKY_DEV_TOOLBOX?: boolean;
  /** An undo button on the Sync Logs details pane. */
  SYNC_LOGS_UNDO_BUTTON?: boolean;
  /** Whether to show mapping direction controls for each column in the column mapping screen. */
  COLUMN_DIRECTION?: boolean;
  /**
   * Prerequisite for any iapp connectors to work. Individual connectors are enabled elsewhere and require this to be enabled
   */
  IAPP?: boolean;
  /** Rollout control for sync preview + record matching */
  SYNC_PREVIEW?: boolean;
  /** Rollout control for reusable connections feature + new connections UI */
  REUSABLE_CONNECTIONS?: boolean;
}

export interface UserLimitData {
  baseCount: number;
  baseLimit: number;
  recordCount: number;
  recordLimit: number;
}

export interface ExternalTable {
  id: UUID;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  order: number;
  remoteId: string;
  supportsWrite: boolean;
  category?: string;
  derivedFromRemoteTableId?: string;
  connectorType: string;
  baseId: string;
  availableConfigExtras?: RemoteTableAvailableConfigExtras;
  lastSchemaFetchTimeMs: number | null;
  orphaned?: boolean;
  debounceMinMs: number;
  debounceMaxMs: number;

  /** If set, this table can't be mapped until the user upgrades to this plan. */
  missingPlan?: MissingRequiredPlan;

  externalColumns: ExternalColumn[] | null;
}
export type RemoteTableAvailableConfigExtras = {
  subtableConfig?: RemoteTableAvailableConfigExtrasItem;
  multiSelectConfig?: RemoteTableAvailableConfigExtrasItem;
  recordFilterConfig?: RemoteTableRecordFilterAvailableConfigExtrasItem;
};

export type RemoteTableAvailableConfigExtrasItem = {
  label: string;
  options?: RemoteTableAvailableConfigExtrasOption[];
};

export type RemoteTableAvailableConfigExtrasOption = {
  label: string;
  value: string;
  restrictions?: {
    syncToExternalDisallowed?: boolean;
  };
};

export type RemoteTableRecordFilterAvailableConfigExtrasItem = {
  allowRecordFiltering: true;
  // TODO: Add any other properties we need to know how to filter records.
};

export type RemoteTableSelectedConfigExtras = {
  subtableValue?: string;
  multiSelectValue?: string[];
  recordFilterValue?: RemoteTableRecordFilter;
};

export type RemoteTableRecordFilter = {
  columnRemoteId: string;
  filterIn: RemoteTableRecordFilterInOptions;
};

export type RemoteTableRecordFilterInOptions = 'if-empty' | 'if-not-empty';

export interface ExternalConnection {
  id: UUID;
  createdAt: Date;
  updatedAt: Date;
  type: string;
}

export interface OAuthAuthorizeCallbackDto {
  forUserId?: string;
  code: string;
}

export interface ExternalColumn {
  id: UUID;
  remoteId: string;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  order: number;
  tableId: string;
  connectorType: string;
  typeMetadata: ColumnTypeMetadata;
  /** For debug only */
  extras?: JsonSafeValue;
  orphaned?: boolean;
}

export interface ExternalBase {
  id: UUID;
  createdAt: Date;
  updatedAt: Date;
  name: string;
  remoteId: string;
  browserUrl?: string;
  noisySource: boolean;
  type: string;
  coreBaseId: string;
  externalConnectionId: string;
  connectorInfo: ConnectorInfo;

  baseBundle?: Record<string, unknown>;

  externalTables: ExternalTable[] | null;
  externalConnection: ExternalConnection | null;
}

export interface CoreBase {
  id: UUID;
  updatedAt: Date;
  name: string;
  syncState: CoreBaseSyncState;
  /** @deprecated This is sync status and should live where other sync status data is. */
  lastSyncTime: Date;
  ownerId: string;
  pinned: boolean;

  leftExternalBase: ExternalBase | null;
  rightExternalBase: ExternalBase | null;
  mappings: MappingsEntity | null;
  recordCount: number | null;
}

export interface MappingsEntity {
  tableMappings: TableMappingEntity[];
}

export type SyncDirectionEntity = 'left' | 'right' | 'both' | 'invalid';

export enum RecordDeleteBehavior {
  // Sync the delete to core (default behaior)
  SYNC = 'sync',
  // Don't sync anything
  DO_NOTHING = 'do_nothing',
}

export interface TableMappingEntity {
  /** ID for this mapping. Should be opaque to the client, used for UI paperwork only. */
  id: string;

  leftTableId: string;
  leftTableRecordDeleteBehavior: RecordDeleteBehavior;
  leftTableSelectedConfigExtras: RemoteTableSelectedConfigExtras | null;

  rightTableId: string;
  rightTableRecordDeleteBehavior: RecordDeleteBehavior;
  rightTableSelectedConfigExtras: RemoteTableSelectedConfigExtras | null;

  // The sync direction between two external tables. Also used for knowing how to initialize new column mappings.
  // All columnMappings.syncDirection will be equal to or more restrictive than this.
  syncDirection: SyncDirectionEntity;
  columnMappings: ColumnMappingEntity[];
}

export interface ColumnMappingEntity {
  /** ID for this mapping. Should be opaque to the client, used for UI paperwork only. */
  id: string;
  leftColumnId: string;
  rightColumnId: string;
  syncDirection: SyncDirectionEntity;
  /** Which side should win when merging a record for this column */
  initializeOnMergeWinner: 'left' | 'right' | 'either';
}

export interface RemoteData {
  remoteTableId: string;
  remoteRecordId: string;
  fields: Record<string, JsonSafeValue>;
}

export interface CmrFormatData {
  fields: Record<string, CmrCellData>;
}

export interface CmrCellData {
  value: JsonSafeValue;
  cellVersion: string;
  metadata: ColumnTypeMetadata;
}

/** A dump of the database's CMR */
export interface DebugCoreMetaRecord {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  version: number;
  data: CmrFormatData;
  etag: string;
  isTombstoned: boolean;
  coreTableId: string;
  externalMetaRecords?: ExternalMetaRecord[];
}

export interface ExternalMetaRecord {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  version: number;
  remoteId: string | null;
  connectorType: string;
  lastPullTime: Date | null;
  lastPushTime: Date | null;
  pushCount: number;
  hostedFiles: unknown;
  remoteData: RemoteData | null;
  cmrFormatData: CmrFormatData | null;
  isTombstoned: boolean;
  etag: string;
  pendingForeignKey: boolean;
  externalTableId: string;
  coreMetaRecordId: string;
  forceEnqueuePush: boolean;
}

export interface SyncItem {
  id: UUID;

  // DetailLevel.BASIC only includes:
  itemSummary: string;
  occurredOn: string; //  ISO-formatted datetime string.
  connectorType: string;
  connectorName: string;
  connectorIcon: string;
  coreBaseId: string;
  coreBaseName: string;
  externalBaseId: string;
  externalBaseName: string;
  externalTableId: string;
  externalTableName: string;
  detailsTitle: string;
}

export interface ListSyncLogsResponse {
  totalCount: number;
  records: SyncLog[];
}
export interface ListSyncErrorsResponse {
  totalCount: number;
  records: SyncError[];
}

export interface SyncItemDisplayField {
  label: string;
  value: string;
}

export interface SyncLog extends SyncItem {
  // Basic includes:
  recordFriendlyName: string;
  remoteRecordId: string;

  actionFriendlyName: string;
  actionType: string;

  // DetailLevel.FULL additionally includes:
  externalRecordId?: UUID;

  /** @deprecated Replaced with fieldDiffs. */
  recordFields?: SyncItemDisplayField[];

  /** The contents of the record, annotated with change information. Changed fields will be ordered first. */
  fieldDiffs?: SyncLogFieldDiff[];
}

export interface SyncLogFieldDiff {
  fieldName: string;
  operation: 'unchanged' | 'added' | 'modified' | 'deleted';

  /**
   * The value of the field BEFORE the change took place. Formatted for display, including whitespace.
   * Always set.
   */
  beforeValue: string;

  /**
   * The value of the field AFTER the change took place. Formatted for display, including whitespace.
   * Will be omitted when `operation` field is 'unchanged'.
   */
  afterValue?: string;
}

export interface SyncError extends SyncItem {
  // DetailLevel.FULL additionally includes:
  code?: string;
  userFacingMessage?: string;
  userAction?: SyncErrorUserAction;
  firstOccurred?: string; // ISO-formatted datetime string.
  lastOccurred?: string; // ISO-formatted datetime string.

  /**
   * An ordered list of fields that can be rendered directly in the UI.
   * These are put in a flat list so they can be added/removed from the server only.
   */
  extraFields?: SyncItemDisplayField[];

  /** A list of fields from the CMR, to give context. */
  recordFields?: SyncItemDisplayField[];

  /** A big ugly rich Json error description. */
  debugJson?: unknown;
}

export interface SyncErrorUserAction {
  summary?: string;
  explanation: string;
  action?: string;
  resources?: { name: string; url: string }[];
}

export interface ClearAndRetrySyncErrorsResponse {
  errorsCleared: number;
  cmrsRetried: number;
}

export interface BaseSyncStatus {
  pushJobs: number;
  syncErrors: boolean;
  lastSyncTimeEpochMs: number | null;
  leftExternalBaseSyncStatus: ExternalBaseSyncStatus;
  rightExternalBaseSyncStatus: ExternalBaseSyncStatus;
}

export interface ExternalBaseSyncStatus {
  // Number of pending/active pushRecord jobs
  pushJobs: number;
  // True if in a polling round or with pending/active webhook / getRecord jobs
  isPolling: boolean;
  // True if initializing
  isInitializing: boolean;
  // True if registering webhooks
  isRegisteringWebhooks: boolean;
}

export enum CoreBaseDetailLevel {
  BASIC = 'basic',

  /** Includes all the nested objects for the schema: ExternalBases, Core/External Tables, Core/External Columns. */
  FULL_BASE_SCHEMA = 'fullBaseSchema',
}

export type ConnectorInfo = {
  connectorType: string;
  displayName: string;

  icons: {
    withBackgroundSvg: string;
  };

  remoteBaseNoun: string;

  supportsRead: boolean;
  supportsWrite: boolean;

  tableCategories: Record<string, ConnectorInfoTableCategory>;

  auth: ConnectorInfoOauthSpec | ConnectorInfoPasswordAuthSpec | ConnectorInfoIAppAuthSpec;
  requiredBaseExtras: ConnectorInfoSetupField[];
  preSyncWarnings: ConnectorInfoPresyncWarning[];

  disableSyncingToSelf: boolean;

  isInBeta: boolean;

  /** If set, this connector can't be used until the user upgrades to this plan. */
  missingPlan?: MissingRequiredPlan;

  documentation?: { text: string; label: string; url: string };
};

export type ConnectorInfoOauthSpec = {
  type: 'oauth';
  authorizeUrl: string;
  scope?: string;
  refresh: { type: 'no_refresh' } | { type: 'refresh' };
  clientId: string;
  requiredItems?: ConnectorInfoSetupField[];
};

export type ConnectorInfoPasswordAuthSpec = {
  type: 'user_provided_fields';
  requiredItems: ConnectorInfoSetupField[];
};

export type ConnectorInfoSetupField = {
  label: string;
  placeholderText: string;
  id: string;
  isOptional?: boolean;
  toggleSwitchLabel?: string;
  instructions?: ConnectorInfoInstructionStep;

  /**
   * If present, the value the user provides for this field will be substituted into the oauth fields `authorizeUrl`
   * and `accessTokenUrl`.
   * For example, if `authorizeUrl` is "https://{store_name}.shopify.com" and this field's value is "store_name", then
   * the user's input will be substituted into the url before they are redirected.
   * NOTE! When this is set, the text field should have extra validations to limit input to [a-zA-Z0-9-], and the user's
   * input should be sanitized before use.
   * This field is ignored for non-oauth connectors.
   */
  oauthUrlParameterKey?: string;
};

export type ConnectorInfoInstructionStep = {
  label: string;
  url: string;
};

export type ConnectorInfoIAppAuthSpec = {
  type: 'iapp';
  iAppIntegrationKey: string;
  extraAuthInstructions?: { message: string };
};

export type ConnectorInfoPresyncWarning = {
  icon: string;
  warning: string;
};

export type ConnectorInfoTableCategory = {
  label: string;
  iconUrl: string;
};

/** A customer plan that is required to unlock a feature. */
export type MissingRequiredPlan = {
  planName: string;

  /** The name of the feature that is gated by this. ex: "Stripe", "Webflow ECommerce" */
  featureName: string;

  /** If true, do not prompt the user to upgrade, just block the feature's use. */
  isComingSoon?: boolean;
};

type RawOAuthAuthorizationResponse = {
  token_type: 'bearer' | 'Bearer';
  access_token: string;
  refresh_token?: string;
  expires_in?: number;
  refresh_expires_in?: number;
} & {
  [extraInfo: string]: JsonSafeValue;
};

export type OAuthAuthorizationData = RawOAuthAuthorizationResponse & {
  whalesync_access_token_created_at_timestamp: number;
  whalesync_refresh_token_created_at_timestamp?: number;
};

export type GetUserEmailSuccess = {
  result: 'success';
  email: string;
};

export type NotSupportedResult = {
  result: 'not_supported';
};

export interface FetchBasePreviewsResponse {
  value: RemoteBasePreview[];
}

export type RemoteBasePreview = {
  remoteId: RemoteBaseId;
  displayName: string;

  /** A external link to this base that the user could navigate to in the browser. */
  browserUrl?: string;

  noisySource: boolean;
};

export type RemoteBaseId = string;

/**
 * The object we pass to connector OAuth calls as a query param. This lets us save state before we redirect to a
 * connector's OAuth URL. We can then restore this state after the connector redirects back to us.
 */
export type OAuthSavedState = { redirectTo?: string; oAuthSource?: OAuthSource; authFlowId?: string };

/** An enum that indicates the place where an OAuth flow started. */
export enum OAuthSource {
  BASE_SETUP = 'base_setup',
  APP_STORE = 'app_store',
}

export type StringBundle = {
  [id: string]: string;
};

export interface BaseBundleExtras {
  [id: string]: JsonSafeValue;
}

/**
 * Extra details returned on a failed request to indicate which fields have validation problems.
 */
export type DataValidationResult = 'ok' | DataValidationProblem;
export type DataValidationProblem = {
  severity?: 'error' | 'warning';
  fieldIssues?: FieldLevelIssues;
};
/** A map from entity id to problem. No indication of what type of entity it is, but they're unique. */
export type FieldLevelIssues = Record<string, FieldLevelIssue[] | undefined>;
export type FieldLevelIssue = {
  message: string;
  severity: 'error' | 'warning';
  restrictToScreen?: 'table-mapping' | 'field-mapping';
};

/**
 * The set of named icons that connectors can use to represent a column.
 * These correspond to the names at https://phosphoricons.com/
 * If no existing icon is appropriate, a new one can be added here and update the client rendering code.
 * To add a new icon, you need to edit:
 * - api/bottlenose/src/connectors/types.ts
 * - [this file] dusky/lib/client/v1/entities.ts
 * - dusky/components/common/connector-column-icon.tsx
 */
export type ConnectorColumnIcon =
  | 'article-medium'
  | 'article'
  | 'barcode'
  | 'brackets-curly'
  | 'calendar-plus'
  | 'calendar'
  | 'caret-circle-double-down'
  | 'caret-circle-down'
  | 'check-square'
  | 'checks'
  | 'circle-dashed'
  | 'circle-wavy-question'
  | 'clock'
  | 'currency-dollar'
  | 'cursor'
  | 'envelope-simple'
  | 'file-html'
  | 'flow-arrow'
  | 'hash'
  | 'image'
  | 'link'
  | 'lock-simple'
  | 'list-bullets'
  | 'list-checks'
  | 'magnifying-glass'
  | 'math-operations'
  | 'number-circle-one'
  | 'paint-roller'
  | 'palette'
  | 'paperclip'
  | 'percent'
  | 'phone'
  | 'spiral'
  | 'star'
  | 'text-aa'
  | 'text-align-left'
  | 'text-h-three'
  | 'timer'
  | 'user-gear'
  | 'user'
  | 'video-camera';

export enum BaseSyncStatusFlag {
  SYNCING = 'Syncing changes',
  INTIAILIZING = 'Starting up',
  REGISTERING_HOOKS = 'Configuring',
  ACTIVE = 'Active',
  INACTIVE = 'Disabled',
}

export type SyncPreview = {
  id: UUID;
  status: SyncPreviewStatus;
  tablePairs: SyncPreviewTablePair[];
  /** Empty if download hasn't started. ISO-formatted datetime string */
  downloadStartedAt?: string;
  /** Empty if download hasn't completed. ISO-formatted datetime string */
  downloadEndedAt?: string;
};

export type SyncPreviewTablePair = {
  // id: UUID;
  tableMappingId: UUID;
  leftTable: SyncPreviewTempTable;
  rightTable: SyncPreviewTempTable;
  matchOnFieldId: UUID | null;
  conflictWinnerSide: Side;
};

export type SyncPreviewTempTable = {
  externalTableId: string;
  status: SyncPreviewTableStatus;
  stats: SyncPreviewTempTableStatus;
  problem?: SyncPreviewProblem;
};

export type SyncPreviewTempTableStatus = {
  sourceRecordsDownloaded: number;
  sourceRecordsIgnored: number;
  destRecordsToCreate: number;
  destRecordsCreated: number;
  destRecordProblems: number;
};

export enum SyncPreviewStatus {
  NEW = 'new',
  DOWNLOADING = 'downloading',
  PLANNING = 'planning',
  IN_REVIEW = 'in_review',
  APPLYING = 'applying',
  DONE = 'done',
  CANCELLED = 'cancelled',
  FAILED = 'failed',
}

export enum SyncPreviewTableStatus {
  /** Tables are reset to MORE_WORK when the parent status changes, to indicate they still need more processing. */
  MORE_WORK = 'more_work',
  /** The table has been processed to the parent's current status and reflects it properly. */
  DONE = 'done',
}

export type SyncPreviewProblem = {
  code: string;
  userFacingMessage: string;
};

/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { IS_V2_UI } from '@/utils/flags';
import Bugsnag from '@bugsnag/js';
import { useAuth, useUser } from '@clerk/nextjs';
import { buildClerkProps } from '@clerk/nextjs/server';
import { UserResource } from '@clerk/types';
import { GetServerSideProps, GetServerSidePropsContext } from 'next';
import { usePathname } from 'next/navigation';
import Router from 'next/router';
import { Dispatch, ReactNode, createContext, useCallback, useContext, useEffect, useReducer } from 'react';
import { closeIntercom, initIntercom } from '../../lib/client/intercom';
import ApiV1 from '../../lib/client/v1/methods';
import { assertUnreachable } from '../../utils/helpers';
import { UserInfo } from '../../utils/types';
import { MantineLoaderCentered } from '../base-widgets-mantine/mantine-loader';
import { FullPageLoader } from '../v2/common/Loader/FullPageLoader';

export interface ClerkAuthState {
  /** The Clerk access token. If a page is auth guarded, this should be available in the initial page load. */
  token: string | null;
  /** The Clerk user. If a page is auth guarded, this is sometimes available in the initial page load. */
  clerkUser: UserResource | null;
  /** The Whalesync user. This is lazy loaded. */
  user: UserInfo | null;
  /** The last time the token was refreshed */
  tokenTimestamp: number;
}

export type ClerkAuthStateAction =
  | { type: 'set_token'; token: string }
  | { type: 'set_clerk_user'; clerkUser: UserResource }
  | { type: 'set_user'; user: UserInfo }
  | { type: 'clear_session' };

/*
 * The public interface to expose to everyone using the context
 */
export interface ClerkAuthContextType {
  /* The current state of auth including the token, Clerk user and Whalesync User */
  authState: ClerkAuthState;
  /* Dispatcher for updating state */
  dispatch: Dispatch<ClerkAuthStateAction>;
  /* A function to signout out of auth */
  signOut: () => void;
}

/*
 * @return Creates an initialized state object
 */
function createAuthState(token: string | null, clerkUser: UserResource | null): ClerkAuthState {
  return { token, clerkUser, user: null, tokenTimestamp: 0 };
}

function clerkAuthReducer(prevState: ClerkAuthState, action: ClerkAuthStateAction): ClerkAuthState {
  switch (action.type) {
    case 'set_token':
      return {
        token: action.token,
        clerkUser: prevState.clerkUser,
        user: prevState.user,
        tokenTimestamp: Date.now(),
      };
    case 'set_clerk_user':
      return {
        token: prevState.token,
        clerkUser: action.clerkUser,
        user: prevState.user,
        tokenTimestamp: prevState.tokenTimestamp,
      };
    case 'set_user':
      return {
        token: prevState.token,
        clerkUser: prevState.clerkUser,
        user: action.user,
        tokenTimestamp: prevState.tokenTimestamp,
      };
    case 'clear_session':
      return {
        token: null,
        clerkUser: null,
        user: null,
        tokenTimestamp: 0,
      };

    default:
      assertUnreachable(action);
  }
}

/*
 * NOTE: this should be moved to RouteUtil after some circular references are resolved
 * @param pathname the URL path to evaluate
 * @returns true if the path is a public page that should pass through the ClerkAuthContextProvider
 */
export function isPublicPage(pathname: string): boolean {
  return (
    pathname !== null &&
    (pathname.startsWith('/health') ||
      pathname.startsWith('/sign-in') ||
      pathname.startsWith('/sign-up') ||
      pathname.startsWith('/account/signup/') ||
      pathname.startsWith('/v2/sign-in') ||
      pathname.startsWith('/v2/sign-up') ||
      pathname.startsWith('/v2/account/signup/') ||
      pathname.startsWith('/api/hello') ||
      pathname.startsWith('/login') ||
      pathname.startsWith('/v2/login'))
  );
}

export const ClerkAuthContext = createContext<ClerkAuthContextType>({
  authState: createAuthState(null, null),
} as ClerkAuthContextType);

const JWT_TOKEN_REFRESH_MS = 10000; // 10 seconds

export const ClerkAuthContextProvider = (props: { children: ReactNode }): JSX.Element => {
  const [authState, dispatch] = useReducer(clerkAuthReducer, createAuthState(null, null));
  const { getToken, signOut } = useAuth();
  const { isLoaded, isSignedIn, user: clerkUser } = useUser();
  const pathname = usePathname();

  const signOutClerk = useCallback(() => {
    /*
     * Clerk handles signouts with an async function and needs special treatment to call it
     */
    if (signOut) {
      // The Clerk singOut function is async
      const asyncSignOut = async () => {
        await signOut();
      };
      asyncSignOut()
        .catch(console.error)
        .finally(() => Router.push('/sign-in'));
    }
  }, [signOut]);

  const loadToken = useCallback(async () => {
    /*
     * Fetch a new JWT token from Clerk. This has to be done using an async function
     */
    const newToken = await getToken({ template: 'whalesync' });
    if (newToken && authState.token !== newToken) {
      dispatch({ type: 'set_token', token: newToken });
    }
  }, [getToken, authState]);

  const setClerkUser = useCallback(
    (token: string, clerkUser: UserResource) => {
      /*
       * Handles setting up the user state once a valid session exists with Clerk
       */
      dispatch({ type: 'set_clerk_user', clerkUser });
      ApiV1.getSignedInUser({ token })
        .then((user) => {
          if (user) {
            dispatch({
              type: 'set_user',
              user: {
                displayName: clerkUser.fullName || '',
                email: clerkUser.primaryEmailAddress?.emailAddress || '',
                whalesyncUser: user,
              },
            });
          } else {
            console.error('Failure from getSignedInUser, signing out of session');
            signOutClerk();
          }
        })
        .catch((e) => {
          console.error(e);
          signOutClerk();
        });
    },
    [signOutClerk],
  );

  useEffect(() => {
    if (isLoaded && isSignedIn) {
      // load the token any time our auth state changes
      loadToken().catch(console.error);
    }
  }, [isLoaded, isSignedIn, loadToken]);

  useEffect(() => {
    /*
     * Evalute the current state of user auth
     */
    if (isLoaded) {
      // The Clerk SDK is initialized properly
      if (isSignedIn && authState.token && !authState.user) {
        // the session is active and a Clerk user is loaded, setup the Whalesync user state
        setClerkUser(authState.token, clerkUser);
      } else if (!isSignedIn && authState.token) {
        // the session is no longer active according to Clerk but we have token set in the state
        // need to clear the session and force us to re-evaluate auth
        dispatch({ type: 'clear_session' });
      }
    }
  }, [isLoaded, isSignedIn, authState.token, authState.user, clerkUser, setClerkUser]);

  useEffect(() => {
    /**
     * Update logging identifiers for the signed-in user.
     */
    if (clerkUser?.id && authState.user) {
      const segmentUserTraits: Record<string, string> = {};
      if (authState.user.email) {
        segmentUserTraits.email = authState.user.email;
      }
      if (authState.user.displayName) {
        segmentUserTraits.displayName = authState.user.displayName;
      }
      window.analytics.identify(clerkUser.id, segmentUserTraits);
      initIntercom(authState.user);
    } else {
      closeIntercom();
    }
  }, [clerkUser, authState.user]);

  /*
   * Periodically refresh the JWT token so the version in authState is as recent as possible
   * TODO: This is a temporary solution to keep the token fresh - ideally this is handled by clerk but we need to
   * change how we are providing the token to all the pages. Instead of storing the token in state, the pages should
   * use the Clerk API to get get the token.
   */
  useEffect(() => {
    const interval = setInterval(() => {
      loadToken().catch(console.error);
    }, JWT_TOKEN_REFRESH_MS);

    return () => clearInterval(interval);
  }, [loadToken]);

  if (isPublicPage(pathname)) {
    // Public pages just pass through and can get rendered w/o auth state initialized
    // NOTE: these pages should not attempt to access any user or auth data
    return <>{props.children}</>;
  }

  if (!authState.token || !authState.user) {
    /*
     * Session not authorized and/or user is not yet identified, show a loading screen while we wait for that workflow
     *  to complete
     */
    return IS_V2_UI ? <FullPageLoader /> : <MantineLoaderCentered />;
  }

  /*
   * Session exist, we have a valid JWT, clerk user is loaded AND whalesync user identified
   * AUTH is complete -- any dependant pages are safe to render and utilize user data
   */
  return (
    <ClerkAuthContext.Provider value={{ authState, dispatch, signOut: signOutClerk }}>
      {props.children}
    </ClerkAuthContext.Provider>
  );
};

export const useClerkAuth = (): ClerkAuthContextType => {
  return useContext(ClerkAuthContext);
};

/**
 * This is just a prototype for now - not actually used yet
 * The Server Side Props fuction that loads the Clerk data and merges it with additon server side props
 * @param additionalServerSideProps Any additional functions to run that generate props
 */
export function authServerSideProps(
  additionalServerSideProps?: GetServerSideProps,
): (context: GetServerSidePropsContext) => Promise<unknown> {
  return async (context: GetServerSidePropsContext): Promise<unknown> => {
    if (additionalServerSideProps) {
      const results = await additionalServerSideProps(context);
      return {
        props: {
          ...('props' in results ? results.props : undefined),
          ...buildClerkProps(context.req),
        },
      };
    } else {
      return { props: { ...buildClerkProps(context.req) } };
    }
  };
}

/**
 * Gets the auth token from `authState`. If there is no auth token available, we return a fake token and force the user
 * to sign out as a side effect.
 *
 * NOTE: This function should only be called on auth-guarded pages.
 * 
 // TODO(chris): We can probably do something better here. Instead of forcing a signout
 */
export function getTokenOrSignOut(authState: ClerkAuthState, signOut: () => void): string {
  if (authState.token === null) {
    Bugsnag.notify({
      name: 'UnexpectedSignoutError',
      message: `Unexpected signout after trying to get the access token on page ${
        typeof window !== 'undefined' ? window.location.href : '"page unknown, running on server"'
      }`,
    });

    if (signOut) {
      signOut();
    }

    return 'user_is_signed_out_token';
  }
  return authState.token;
}

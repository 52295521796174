import { UUID } from '@/lib/client/v1/entities';
import { UrlObject } from 'url';
import { SetupStepIndex, ViewBaseCoreBaseId, ViewBaseMode } from '../components/contexts/setup-context';

export class RouteUrls {
  static viewBasePageUrl(query: {
    coreBaseId: ViewBaseCoreBaseId;
    mode: ViewBaseMode;
    step: SetupStepIndex;
  }): UrlObject {
    return { pathname: '/dashboard/bases/[coreBaseId]/[mode]/[step]', query };
  }

  static devComponentPlaygroundUrl = '/dev/component-playground';
  static devSchemaViewerUrl(coreBaseId?: string): UrlObject {
    return { pathname: '/dev/schema-viewer', query: { coreBaseId } };
  }
  static devTokenUrl = '/dev/token';
  static issuesPageUrl = '/issues';
  static logsPageUrl = '/logs';
  static dashboardUrl = '/dashboard';
  static manageSubscription = '/account/manage';
  static profilePageUrl = '/account/profile';
  static signInPageUrl = '/sign-in';
  static signUpPageUrl = '/sign-up';
  static loginPageUrl = '/login';

  static signInPageWithRedirect(redirectUrl: string): string {
    return `${RouteUrls.signInPageUrl}?redirect_url=${encodeURIComponent(redirectUrl)}`;
  }

  static signUpPageWithRedirect(redirectUrl: string): string {
    return `${RouteUrls.signUpPageUrl}?redirect_url=${encodeURIComponent(redirectUrl)}`;
  }

  // V2 URLs.
  static v2Syncs = '/v2/syncs';
  static v2NewSync = '/v2/syncs/new';
  static v2Issues = '/v2/issues';
  static v2Operations = '/v2/operations';
  static v2DevOptions = '/v2/developer';
  static v2Connections = '/v2/connections';
  static v2Settings = '/v2/settings';
  static v2ManageSubscription = '/v2/subscription/manage';
  static v2Profile = '/v2/settings/profile';
  static v2DevTools = '/v2/settings/dev-tools';
  static v2HelpAndSupport = '/v2/help-and-support';
  static v2AccountPayments = '/v2/account/payment';
  static v2AccountSignup = '/v2/account/signup';
  static v2SignIn = '/v2/sign-in';
  static v2SignUp = '/v2/sign-up';

  // Used in DetailLayout. It does not accept UrlObject

  // Temporarely we do not show the overview so we go to the TM page directly
  static v2Sync = (coreBaseId: UUID): string => `/v2/syncs/${coreBaseId}/table-mappings`;
  static v2Sync_operations = (coreBaseId: UUID): string => `/v2/syncs/${coreBaseId}/operations`;

  static v2SyncEdit = (coreBaseId: UUID, step?: number): string =>
    `/v2/syncs/edit/${coreBaseId}${step ? `?step=${step}` : ''}`;

  static v2TableMappings = ({ coreBaseId, isNew }: { coreBaseId: string; isNew?: boolean }): string =>
    `/v2/syncs/${coreBaseId}/table-mappings` + (isNew ? `?new=${new Date().getTime()}` : '');

  static v2TableMapping = ({
    coreBaseId,
    tableMappingId,
    isNew,
  }: {
    coreBaseId: string;
    tableMappingId: string;
    isNew: boolean;
  }): string =>
    `/v2/syncs/${coreBaseId}/table-mappings/${tableMappingId}/field-mappings` +
    (isNew ? `?init=${new Date().getTime()}` : '');

  static v2TableMappingEditor = ({
    coreBaseId,
    tableMappingId,
    isNew,
  }: {
    coreBaseId: string;
    /** falsy converted to 0 to satisfy NextJS path resolvinbg*/
    tableMappingId?: string;
    isNew?: boolean;
  }): string =>
    `/v2/syncs/${coreBaseId}/table-mapping-editor/${Boolean(tableMappingId) ? tableMappingId : '0'}` +
    (isNew ? `?init=${new Date().getTime()}` : '');

  static v2Issue = (id: string): string => `/v2/issues/${id}`;
  static v2Operation = (id: string): string => `/v2/operations/${id}`;
  static v2PaymentPageWithProductType = (productType: string): string =>
    `${RouteUrls.v2AccountPayments}/${encodeURIComponent(productType)}`;

  static v2AccountSignupWithProductType = (productType: string): string =>
    `${RouteUrls.v2AccountSignup}/${encodeURIComponent(productType)}`;

  static v2SignInPageWithRedirect(redirectUrl: string): string {
    return `${RouteUrls.v2SignIn}?redirect_url=${encodeURIComponent(redirectUrl)}`;
  }

  static v2SignUpPageWithRedirect(redirectUrl: string): string {
    return `${RouteUrls.v2SignUp}?redirect_url=${encodeURIComponent(redirectUrl)}`;
  }

  static issuesForBasePageUrl(coreBaseId: string): string {
    const filters = {
      coreBaseId,
    };

    return `${this.v2Issues}?filters=${encodeURIComponent(JSON.stringify(filters))}`;
  }

  static publicRoutePatterns = [
    '/sign-in',
    '/sign-up',
    '/health',
    '/account/signup/(.*)',
    '/api/hello',
    '/login',
    '/v2/sign-in',
    '/v2/sign-up',
    '/v2/account/signup/(.*)',
  ];

  static subscriptionRoutePatterns = [
    RouteUrls.dashboardUrl,
    RouteUrls.logsPageUrl,
    RouteUrls.issuesPageUrl,
    RouteUrls.v2Syncs,
    RouteUrls.v2Issues,
    RouteUrls.v2Operations,
    RouteUrls.v2Connections,
  ];

  static isPublicRoute(pathname: string): boolean {
    return RouteUrls.publicRoutePatterns.some((pattern) => new RegExp(pattern).test(pathname));
  }

  static isSubscribedOnlyRoute(pathname: string): boolean {
    return RouteUrls.subscriptionRoutePatterns.some((pattern) => new RegExp(pattern).test(pathname));
  }
}
